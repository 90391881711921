import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  LinearProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HttpService from "../services/http.service";
import { statisticsURL } from "../services/url.service";
import { useEffect, useState } from "react";
const Dashboard = () => {
  const navigate = useNavigate();

  const [statisticsData, setStatisticsData] = useState();
  const [loading, setLoading] = useState(false);
  const getStatistics = () => {
    setLoading(true);
    HttpService.get(statisticsURL)
      .then((res) => {
        setStatisticsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStatistics();
  }, []);
  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Box sx={{ position: "fixed", top: "70px", width: "100%", zIndex: 999 }}>
        {loading && (
          <LinearProgress
            sx={{
              backgroundColor: "#f1f5f9",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#ff6412ad",
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          px: { xs: 2, md: 5 },
          py: { xs: 2, md: 4 },
        }}
      >
        {" "}
        <Typography
          sx={{
            fontSize: { xs: "16px", sm: "22px" },
            fontWeight: "600",
            color: "#1e293b",
            fontFamily: "Open Sans",
            mb: 3,
            display: { xs: "block", md: "none" },
          }}
        >
          Dashboard.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: 3,

            justifyContent: "center",
            //   alignItems: { xs: "center", md: "start" },
          }}
        >
          <Card
            onClick={() => {
              navigate("/shipments");
            }}
            sx={{
              border: `1px solid #ff6412`,
              borderRadius: 2,
              minWidth: 275,
              backgroundColor: "#ffffff",
            }}
          >
            <CardContent>
              <Typography variant="h6" color="#ff6412" gutterBottom>
                Shipments
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Total Shipments
                  </Typography>
                  <Typography variant="h5">
                    {statisticsData?.total_shipment}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Enroute</Typography>
                  <Typography variant="h5">
                    {statisticsData?.total_shipment_enroute}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Delivered</Typography>
                  <Typography variant="h5">
                    {statisticsData?.total_shipment_delivered}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Canceled</Typography>
                  <Typography variant="h5">
                    {statisticsData?.total_shipment_cancelled}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card
            onClick={() => {
              navigate("/samples");
            }}
            sx={{
              border: `1px solid #ff6412`,
              borderRadius: 2,
              minWidth: 275,
              backgroundColor: "#ffffff",
            }}
          >
            <CardContent>
              <Typography variant="h6" color="#ff6412" gutterBottom>
                Samples
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography color="text.secondary">In shipments</Typography>
                  <Typography variant="h5">
                    {statisticsData?.total_sample}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Enroute</Typography>
                  <Typography variant="h5">
                    {statisticsData?.total_sample_enroute}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Delivered</Typography>
                  <Typography variant="h5">
                    {statisticsData?.total_sample_delivered}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Canceled from shipment
                  </Typography>
                  <Typography variant="h5">
                    {statisticsData?.total_sample_cancelled}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Box>{" "}
    </Box>
  );
};

export default Dashboard;
