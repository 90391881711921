import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Tab = ({ bars, getTabId, current }) => {
  const [activeTab, setActiveTab] = useState(current);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    getTabId(tabId);
  };

  useEffect(() => {
    setActiveTab(current);
  }, [current]);

  return (
    <Box sx={{ display: "flex", width: "100%", overflowX: "auto" }}>
      {bars.map((bar, index) => (
        <Box
          key={bar.id}
          onClick={() => {
            if (bars.length - 1 !== index) {
              handleTabClick(bar.id);
            }
          }}
          sx={{
            borderBottom:
              activeTab === bar.id ? "2px solid #2BA1A1" : "1px solid #EAECF0",
            py: 1,
            width: bars.length - 1 === index ? "100%" : "auto",
            minWidth: "max-content",
            cursor: bars.length - 1 === index ? "default" : "pointer",
            textAlign: "center",
            px: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontSize: "15px",
            }}
          >
            {bar.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Tab;
