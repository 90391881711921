import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const Input = styled("input")({
  display: "none",
});

const FileInput = ({
  uploadlabel = "Upload",
  textColor = "#000",
  bgColor = "#fff",
  handleChange,
  idn = "contained-button-file",
  acc = "*",
}) => {
  return (
    <div style={{ width: "100%" }}>
      <label htmlFor={idn}>
        <Input
          accept={acc}
          id={idn}
          multiple
          type="file"
          onChange={handleChange}
        />
        <Button
          startIcon={<UploadFileIcon sx={{ color: "#ff6412" }} />}
          variant="contained"
          component="span"
          fullWidth
          style={{
            backgroundColor: bgColor,
            color: textColor,
            borderRadius: "5px",
            boxShadow: "none",
            textTransform: "none",
            fontSize: 12,
            height: "40px",
            lineHeight: 1.5,
            display: "flex",
            width: "100%",
          }}
        >
          <span>{uploadlabel}</span>
        </Button>
      </label>
    </div>
  );
};

FileInput.propTypes = {
  label: PropTypes.string,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  handleChange: PropTypes.func,
};

export default FileInput;
