import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import HttpService from "../services/http.service";
import { shipmentURL } from "../services/url.service";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const TrackingInfo = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [trackingData, setTrackingData] = useState(null);
  const { shipmentId } = useParams();

  const handleTracking = () => {
    setLoading(true);
    HttpService.get(shipmentURL + "/" + shipmentId + "/tracking")
      .then((res) => {
        setTrackingData(res?.data?.data || {});
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentStatusIndex = trackingData?.data?.tracking_updates
    ?.reverse()
    ?.findIndex(
      (update) => update.status === trackingData?.data?.current_status,
    );

  return (
    <Box sx={{ height: "calc(100vh - 70px - 80px)", position: "relative" }}>
      <Box sx={{ position: "fixed", top: "70px", width: "100%", zIndex: 999 }}>
        {loading && (
          <LinearProgress
            sx={{
              backgroundColor: "#f1f5f9",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#ff6412ad",
              },
            }}
          />
        )}
      </Box>
      <Box sx={{ px: { xs: 1, sm: 2, md: 5 }, py: { xs: 2, md: 4 } }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "22px" },
              fontWeight: "600",
              color: "#1e293b",
              fontFamily: "Open Sans",
            }}
            variant="h5"
            gutterBottom
          >
            Shipment Tracking Progress
          </Typography>{" "}
        </Box>

        {trackingData && (
          <Box sx={{ width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              Shipment Tracking ID: {trackingData?.tracking_id}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Carrier: {trackingData?.carrier}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Delivery Info: {trackingData?.data?.delivery_info}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Status: {trackingData?.data?.current_status}
            </Typography>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {trackingData?.data?.tracking_updates && (
                <Stepper orientation="vertical" sx={{ marginTop: 2 }}>
                  {trackingData?.data?.tracking_updates?.map(
                    (update, index) => (
                      <Step key={index} active={true}>
                        <StepLabel
                          sx={{ ml: "3px" }}
                          icon={
                            trackingData?.data?.current_status ===
                            update.status ? (
                              <CheckCircleIcon
                                sx={{
                                  color: "#4caf50",
                                  fontSize: 25,
                                  ml: "-3px",
                                }}
                              />
                            ) : (
                              <CircleIcon
                                sx={{
                                  color:
                                    index <= currentStatusIndex
                                      ? "#4caf50"
                                      : "#9e9e9e",
                                  fontSize: 20,
                                }}
                              />
                            )
                          }
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color:
                                index <= currentStatusIndex
                                  ? "#4caf50"
                                  : "#9e9e9e",
                            }}
                          >
                            {update.status}
                          </Typography>
                        </StepLabel>
                        <StepContent>
                          <Typography variant="body2" color="text.secondary">
                            {update.date} - {update.time}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Location: {update.location}
                          </Typography>
                        </StepContent>
                      </Step>
                    ),
                  )}
                </Stepper>
              )}
            </Box>
            <Paper square elevation={0} sx={{ padding: 2, marginTop: 2 }}>
              <Typography variant="body1">
                Final Status: {trackingData?.data?.current_status}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Delivered on: {trackingData?.data?.delivery_info}
              </Typography>
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TrackingInfo;
