import HttpService from "./http.service";
import {
  drxnaUrl,
  topicURL,
  uploadSampleSheetURL,
  courseContentURL,
  userContentURL,
  samplesURL,
  xmiURL,
  userURL,
} from "./url.service";

export const getUser = (id) => {
  return HttpService.get(userURL + "?user_id=" + id, {});
};
export const ordersListGeter = () => {
  return HttpService.get(drxnaUrl + "workflow/order?as_minion=true");
};

export const actionGeter = (id) => {
  return HttpService.get(
    drxnaUrl + "workflow/order/" + id + "/action?as_minion=true",
  );
};

export const actionSubmitter = (id, data) => {
  return HttpService.post(
    drxnaUrl + "workflow/order/" + id + "/action?as_minion=true",
    data,
  );
};

export const uploadFile = (file) => {
  let fd = new FormData();
  fd.append("files", file);
  return HttpService.post(uploadSampleSheetURL, fd);
};

export const createIndependantTopic = (topicdata) => {
  return HttpService.post(topicURL + "/", topicdata);
};

export const getIndependantTopics = () => {
  return HttpService.get(topicURL + "/", {});
};

export const deleteIndependantTopic = (topic_id) => {
  return HttpService.remove(topicURL + "/" + topic_id, {});
};

export const getTopicContent = (top_id) => {
  return HttpService.get(topicURL + "/" + top_id + "/content", {});
};

export const createTopicContent = (top_id, content) => {
  return HttpService.post(topicURL + "/" + top_id + "/content", content);
};
export const updateTopicContent = (top_id, cont_id, cont_data) => {
  return HttpService.put(
    courseContentURL + "/" + cont_id + "?topic_id=" + top_id,
    cont_data,
  );
};

export const uploadMedia = (upimg) => {
  const fd = new FormData();
  fd.append("files", upimg);
  return HttpService.multi_part_post(userContentURL + "/media", fd);
};

export const getExcludedSamplesLot = (org) => {
  return HttpService.get(
    `${xmiURL}samplelot/list_lots?&excluded_tags=drxna&external_id_only=true`,
    {},
  );
};

export const getSamplesByLot = (lotid, orgid) => {
  return HttpService.get(samplesURL + "?lot_id=" + lotid, {});
};

export const getDrxnaSamplesLot = (org) => {
  return HttpService.get(
    `${xmiURL}samplelot/list_lots?org_id=` + org + "&tags=drxna",
    {},
  );
};

export const getSamples = (p, perpage = 10) => {
  return HttpService.get(
    `${drxnaUrl}sample?per_page=${perpage}&page=${p}&only_with_patient_id=true`,
    {},
  );
};
export const getSamplesCreated = (p, perpage = 10) => {
  return HttpService.get(
    `${drxnaUrl}sample?per_page=${perpage}&page=${p}&only_with_patient_id=true&status=created&exclude_in_shipment=true`,
    {},
  );
};

export const getSampleByID = (id) => {
  return HttpService.get(samplesURL + id, {});
};
