import { Box, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SendIcon from "@mui/icons-material/Send";
import DashboardIcon from "@mui/icons-material/Dashboard";

const BottomNav = () => {
  const location = useLocation();
  const shipmentTab =
    location.pathname.includes("/shipment/") ||
    location.pathname.includes("/track/");
  const sampleTab = location.pathname.includes("/sample");

  return (
    <Box
      sx={{
        width: "100%",
        display: { xs: "flex", md: "none" },
        bottom: 0,
        left: 0,
        height: "80px",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "36%",
          maxWidth: "200px",
          height: "50px",
        }}
      >
        <NavLink
          to="/samples"
          className={({ isActive }) => {
            return isActive || sampleTab ? "activeNavLink" : "inActiveNavLink";
          }}
          style={{
            height: "100%",
            borderRadius: "10px 0 0 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <FilterAltIcon sx={{ rotate: "180deg", fontSize: "25px" }} />

            <Typography sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
              Samples
            </Typography>
          </Box>
        </NavLink>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "80px",
          height: "50px",
        }}
      >
        <NavLink
          to="/dashboard"
          className={({ isActive }) => {
            return isActive ? "activeNavLink" : "inActiveNavLink";
          }}
          style={{
            height: "100%",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <DashboardIcon sx={{ fontSize: "45px" }} />
          </Box>
        </NavLink>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "36%",
          maxWidth: "200px",
          height: "50px",
        }}
      >
        <NavLink
          to="/shipments"
          className={({ isActive }) => {
            return isActive || shipmentTab
              ? "activeNavLink"
              : "inActiveNavLink";
          }}
          style={{
            height: "100%",
            borderRadius: "0 10px 10px 0",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              gap: 2,
            }}
          >
            <Typography sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
              Shipments
            </Typography>
            <SendIcon sx={{ fontSize: "25px" }} />
          </Box>
        </NavLink>
      </Box>
    </Box>
  );
};

export default BottomNav;
