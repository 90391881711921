import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const styles = {
  buttonContainer: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "12px",
    color: "#fff",
    backgroundColor: "#4a90e2",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s ease, transform 0.2s ease",
    outline: "none",
  },
  buttonHover: {
    backgroundColor: "#357ABD",
  },
  buttonActive: {
    transform: "scale(0.95)",
  },
};

const ImageViewer = ({ url }) => {
  const usertoken = localStorage.getItem("usertoken");
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        maxWidth: "600px",
        margin: "auto",
      }}
    >
      <TransformWrapper
        initialScale={1}
        minScale={0.5}
        maxScale={3}
        centerOnInit={true}
        wheel={{ step: 0.1 }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <TransformComponent>
              <img
                src={`${url}?authorization=${usertoken}`}
                alt="Viewer"
                style={{ width: "100%", height: "auto", borderRadius: "8px" }}
              />
            </TransformComponent>

            <div style={styles.buttonContainer}>
              <button style={styles.button} onClick={() => zoomIn()}>
                Zoom In
              </button>
              <button style={styles.button} onClick={() => zoomOut()}>
                Zoom Out
              </button>
              <button style={styles.button} onClick={() => resetTransform()}>
                Reset
              </button>
            </div>
          </>
        )}
      </TransformWrapper>
    </div>
  );
};

export default ImageViewer;
