import React, { useState, useRef, useEffect } from "react";
import { uploadFile } from "../services/instruments.service";

function VideoRecorder({
  onUpdateInspection = () => {},
  setInspectionData = () => {},
  allData,
  id,
  label,
}) {
  const [recording, setRecording] = useState(false);
  const [cameraFacing, setCameraFacing] = useState("environment");
  const [time, setTime] = useState(0);
  const mediaRecorder = useRef(null);
  const videoRef = useRef(null);
  const recordedChunks = useRef([]);
  const streamRef = useRef(null);
  const timerRef = useRef(null);

  const handleUploads = (file) => {
    uploadFile(file)
      .then((res) => {
        let pathuri = res?.data?.data[0];
        const isnpData = {
          ...allData,
          video_list: [
            ...allData?.video_list,
            { title: pathuri?.title || "", id: pathuri?.id },
          ],
        };
        onUpdateInspection(isnpData, false, "video");
        setInspectionData(isnpData);
      })
      .catch((e) => {});
  };

  // Initialize the camera preview
  // const initializeCamera = async () => {
  //   const constraints = {
  //     video: { facingMode: cameraFacing },
  //     audio: true,
  //   };

  //   // Stop any existing streams if they are already running
  //   if (streamRef?.current) {
  //     streamRef?.current?.getTracks()?.forEach((track) => track?.stop());
  //   }

  //   try {
  //     // Try to get user media
  //     streamRef.current =
  //       await navigator?.mediaDevices?.getUserMedia(constraints);
  //     videoRef.current.srcObject = streamRef?.current;
  //   } catch (error) {
  //     if (
  //       error.name === "NotAllowedError" ||
  //       error.name === "PermissionDeniedError"
  //     ) {
  //     } else {
  //       console.error("Error accessing media devices:", error);
  //     }
  //   }
  // };

  // const startRecording = () => {
  //   recordedChunks.current = [];
  //   mediaRecorder.current = new MediaRecorder(streamRef.current, {
  //     mimeType: "video/webm",
  //   });

  //   mediaRecorder.current.ondataavailable = (event) => {
  //     if (event?.data?.size > 0) recordedChunks?.current?.push(event?.data);
  //   };

  //   mediaRecorder.current.onstop = () => {
  //     const blob = new Blob(recordedChunks.current, { type: "video/webm" });
  //     const file = new File([blob], `${label}_video_capture_${id}.webm`, {
  //       type: "video/webm",
  //     });
  //     handleUploads(file);
  //     clearInterval(timerRef.current);
  //     setTime(0);
  //   };

  //   mediaRecorder?.current.start();
  //   setRecording(true);
  //   startTimer();
  // };
  const initializeCamera = async () => {
    const constraints = {
      video: { facingMode: cameraFacing },
      audio: true,
    };

    // Stop any existing streams if they are already running
    if (streamRef?.current) {
      streamRef?.current?.getTracks()?.forEach((track) => track?.stop());
    }

    try {
      streamRef.current =
        await navigator?.mediaDevices?.getUserMedia(constraints);
      videoRef.current.srcObject = streamRef?.current;
    } catch (error) {
      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        alert(
          "Camera access denied. Please enable camera permissions in your settings.",
        );
      } else {
        console.error("Error accessing media devices:", error);
      }
    }
  };

  const startRecording = () => {
    // Check if the stream exists, especially on mobile
    if (!streamRef.current) {
      alert("Camera not accessible. Please refresh and allow camera access.");
      return;
    }

    recordedChunks.current = [];
    try {
      mediaRecorder.current = new MediaRecorder(streamRef.current, {
        mimeType: "video/webm",
      });
    } catch (error) {
      alert("Recording not supported on this device/browser.");
      return;
    }

    mediaRecorder.current.ondataavailable = (event) => {
      if (event?.data?.size > 0) recordedChunks?.current?.push(event?.data);
    };

    mediaRecorder.current.onstop = () => {
      const blob = new Blob(recordedChunks.current, { type: "video/webm" });
      const file = new File([blob], `${label}_video_capture_${id}.webm`, {
        type: "video/webm",
      });
      handleUploads(file);
      clearInterval(timerRef.current);
      setTime(0);
    };

    mediaRecorder?.current.start();
    setRecording(true);
    startTimer();
  };

  const stopRecording = () => {
    mediaRecorder?.current.stop();
    setRecording(false);
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const switchCamera = async () => {
    setCameraFacing((prevFacing) =>
      prevFacing === "user" ? "environment" : "user",
    );
  };

  useEffect(() => {
    initializeCamera();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraFacing]);

  useEffect(() => {
    // initializeCamera();
    return () => {
      if (streamRef?.current) {
        streamRef?.current?.getTracks()?.forEach((track) => track?.stop());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        style={{
          width: "100%",
          maxWidth: "500px",
          border: "1px solid #ccc",
          borderRadius: "8px",
        }}
      />
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        {recording ? (
          <button onClick={stopRecording}>Stop Recording</button>
        ) : (
          <button onClick={startRecording}>Start Recording</button>
        )}
        <button onClick={switchCamera}>
          Switch Camera ({cameraFacing === "user" ? "Front" : "Back"})
        </button>
      </div>
      {recording && (
        <div>
          <h4>
            Recording Time: {Math.floor(time / 60)}:
            {String(time % 60).padStart(2, "0")}
          </h4>
        </div>
      )}
    </div>
  );
}

export default VideoRecorder;
