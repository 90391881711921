import { Button } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import React, { useState, useRef } from "react";
import Webcam from "react-webcam";

import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

import { uploadFile } from "../services/instruments.service";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  height: 250,
  facingMode: FACING_MODE_ENVIRONMENT,
};

export const WebcamCapture = ({
  imagec,
  name,
  handleCaptureImage,
  instruction,
}) => {
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_ENVIRONMENT);

  const handleClick = () => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER,
    );
  };

  const [image, setImage] = useState(imagec);
  const webcamRef = useRef(null);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();

    let blob = new Blob([imageSrc], { type: "image/jpg" });
    let sid = "CaptureIMGminion.jpg";
    var capdata = new File([blob], sid + "", {
      lastModified: new Date().getTime(),
    });
    let uid = localStorage.getItem("userid");
    let orgid = localStorage.getItem("orgid");

    if (instruction) {
      handleCaptureImage(imageSrc);
    } else {
      uploadFile(capdata, orgid, uid, "")
        .then((res) => {
          let pathuri = res.data?.url;
          handleCaptureImage(pathuri);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    setImage(imageSrc);
  };

  return (
    <div className="webcam-container">
      <div
        className="webcam-img"
        style={{
          overflow: "hidden",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
          border: "1px solid #f1f5f9",
        }}
      >
        {image === "" ? (
          <>
            <Webcam
              audio={false}
              width={"100%"}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                ...videoConstraints,
                facingMode,
              }}
            />
          </>
        ) : (
          <img src={image} alt="" style={{ width: "100%" }} />
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        {image !== "" ? (
          <Button
            startIcon={
              <CameraAltOutlinedIcon sx={{ color: "#1e5267", ml: 1 }} />
            }
            variant="contained"
            component="span"
            style={{
              backgroundColor: "#f2f2f2",
              color: "#000",
              borderRadius: "5px",
              boxShadow: "none",
              textTransform: "none",
              fontSize: 12,
              fontFamily: "Open Sans",
              height: "40px",
              lineHeight: 1.5,
              display: "flex",
              alignItems: "center",
              width: "60%",
            }}
            onClick={(e) => {
              e.preventDefault();
              setImage("");
            }}
          >
            <span>{"Recapture " + name + " "}</span>
          </Button>
        ) : (
          <Button
            startIcon={<CameraAltOutlinedIcon sx={{ color: "#1e5267" }} />}
            variant="contained"
            component="span"
            style={{
              backgroundColor: "#f2f2f2",
              color: "#000",
              borderRadius: "5px",
              boxShadow: "none",
              textTransform: "none",
              fontSize: 12,
              height: "40px",
              lineHeight: 1.5,
              display: "flex",
              alignItems: "center",
              width: "60%",
              fontFamily: "Open Sans",
            }}
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
          >
            Capture {name}
          </Button>
        )}

        <Button
          variant="outlined"
          startIcon={<CameraswitchIcon />}
          onClick={handleClick}
          sx={{ textTransform: "none", width: "40%", fontFamily: "Open Sans" }}
        >
          Switch
        </Button>
      </div>
    </div>
  );
};
