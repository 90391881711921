import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SendIcon from "@mui/icons-material/Send";
import { useLocation } from "react-router-dom";

import "../index.css";

const LeftSidebar = ({ onToggle }) => {
  const location = useLocation();
  const isOnShipments =
    location.pathname.includes("/shipment/") ||
    location.pathname.includes("/track/");
  const isOnSamples = location.pathname.includes("/sample/");
  const [isExpanded, setIsExpanded] = useState(false);
  const handleDrawerToggle = () => {
    setIsExpanded(!isExpanded);
    onToggle(!isExpanded);
  };
  return (
    <Box
      sx={{
        background: "#fbfbfb",
        borderRight: "1px solid #e2e8f0",
        width: isExpanded ? "200px" : "60px",
        position: { xs: isExpanded ? "fixed" : "relative", md: "relative" },
        bottom: 0,
        mt: "1px",
        transition: "width .2s",
        height: "calc(100vh - 71px)",
        zIndex: 999,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "90%",
          display: "flex",
        }}
      >
        <NavLink
          to="/dashboard"
          className={({ isActive }) => {
            return isActive ? "activeNavLink" : "inActiveNavLink";
          }}
          style={{
            padding: "10px 0 10px 0",
            borderRadius: "10px",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: isExpanded ? 1.5 : 0,
              justifyContent: isExpanded ? "start" : "center",
            }}
          >
            <DashboardIcon
              fontSize="small"
              sx={{ ml: isExpanded ? "15px" : "0" }}
            />
            {isExpanded && (
              <Typography sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                Dashboard
              </Typography>
            )}
          </Box>
        </NavLink>
      </Box>

      <Box
        sx={{
          width: "90%",
          display: "flex",
        }}
      >
        <NavLink
          to="/samples"
          className={({ isActive }) => {
            return isActive || isOnSamples
              ? "activeNavLink"
              : "inActiveNavLink";
          }}
          style={{
            padding: "10px 0 10px 0",
            borderRadius: "10px",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: isExpanded ? 1.5 : 0,
              justifyContent: isExpanded ? "start" : "center",
            }}
          >
            <FilterAltIcon
              fontSize="small"
              sx={{ rotate: "180deg", ml: isExpanded ? "15px" : "0" }}
            />
            {isExpanded && (
              <Typography sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                Samples
              </Typography>
            )}
          </Box>
        </NavLink>
      </Box>
      <Box
        sx={{
          width: "90%",
          display: "flex",
        }}
      >
        <NavLink
          to="/shipments"
          className={({ isActive }) => {
            return isActive || isOnShipments
              ? "activeNavLink"
              : "inActiveNavLink";
          }}
          style={{
            padding: "10px 0 10px 0",
            marginTop: "15px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: isExpanded ? 1.5 : 0,
              justifyContent: isExpanded ? "start" : "center",
            }}
          >
            <SendIcon fontSize="small" sx={{ ml: isExpanded ? "15px" : "0" }} />
            {isExpanded && (
              <Typography sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                Shipments
              </Typography>
            )}
          </Box>
        </NavLink>
      </Box>
      {/* <Box
        sx={{
          width: "90%",
          display: "flex",
        }}
      >
        <NavLink
          to="/workflows"
          className={({ isActive }) => {
            return isActive ? "activeNavLink" : "inActiveNavLink";
          }}
          style={{
            padding: "10px 0 10px 0",
            marginTop: "15px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: isExpanded ? 1.5 : 0,
              justifyContent: isExpanded ? "start" : "center",
            }}
          >
            <BiotechIcon sx={{ ml: isExpanded ? "15px" : "0" }} />
            {isExpanded && (
              <Typography sx={{ fontFamily: "Sfregular", fontSize: "16px" }}>
                {" "}
                Workflows
              </Typography>
            )}
          </Box>
        </NavLink>
      </Box> */}

      <Box
        onClick={handleDrawerToggle}
        sx={{
          width: "40px",
          height: "40px",
          background: "#fff",
          borderRadius: "20px",
          position: "absolute",
          right: "-20px",
          top: "43vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "35px",
            height: "35px",
            background: "#ffb28a",
            borderRadius: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isExpanded ? (
            <ArrowBackIosNewIcon fontSize="18px" sx={{ color: "#fff" }} />
          ) : (
            <ArrowForwardIosIcon fontSize="18px" sx={{ color: "#fff" }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LeftSidebar;
