export const xmiURL = process.env.REACT_APP_XMI_URL;

export const loginURL = xmiURL + "login";

export const userURL = xmiURL + "get_user";

export const drxnaUrl = process.env.REACT_APP_DRXNA_URL;

export const samplesURL = drxnaUrl + "sample/";

export const shipmentURL = drxnaUrl + "shipment";

export const statisticsURL = shipmentURL + "/statistics";

export const uploadURL = drxnaUrl + "media/";

export const contentURL = process.env.REACT_APP_CONTENT_URL;

export const uploadSampleSheetURL = contentURL + "user/media";

export const topicURL = contentURL + "topic";

export const courseContentURL = contentURL + "content";

export const userContentURL = contentURL + "user";

export const userMediaBaseURL = contentURL + "user/media";
