import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import SampleInspection from "./SampleInspection";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionCustom = ({
  sample,
  shipmentDetail,
  removeSampleToShipment,
  loading,
  setLoading,
  showToast,
  samplesInfo,
  setSamplesInfo = () => {},
}) => {
  // const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion
    // onChange={(event, expanded) => {
    //   setIsExpanded(expanded);
    // }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{ background: "#f1f5f9" }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontFamily: "Open Sans" }}>
            ID: {sample?.sample_id}
          </Typography>

          {shipmentDetail?.shipment_status === "created" && (
            <Button
              variant="outlined"
              color="warning"
              disabled={loading}
              onClick={(e) => {
                e.stopPropagation();
                removeSampleToShipment(sample?.sample_id);
              }}
              sx={{ textTransform: "none" }}
            >
              Remove
            </Button>
          )}
        </Box>
      </AccordionSummary>

      {/* {isExpanded && ( */}
      <AccordionDetails>
        <SampleInspection
          setSamplesInfo={(val) => {
            setSamplesInfo(val);
          }}
          sampleId={sample?.sample_id}
          setLoading={(val) => {
            setLoading(val);
          }}
          showToast={(message, success) => {
            showToast(message, success);
          }}
        />
      </AccordionDetails>
      {/* )} */}
    </Accordion>
  );
};

export default AccordionCustom;
