import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getSamples } from "../services/drxna.service";
import CloseIcon from "@mui/icons-material/Close";
import HttpService from "../services/http.service";
import { drxnaUrl } from "../services/url.service";
import { useLocation, useNavigate } from "react-router-dom";

import ListFormatIcon from "@mui/icons-material/FormatListBulleted";
import GridFormatIcon from "@mui/icons-material/WindowOutlined";
import { isMobile } from "../utils/screen";

const LotsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [selectedSampleIds, setSelectedSampleIds] = useState([]);
  const [gridMode, setGridMode] = useState(isMobile());

  const [samplesList, setSamplesList] = useState([]);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [shipmentName, setShipmentName] = useState("");
  const [prefix, setPrefix] = useState("");

  function generateShipmentName() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const shipmentName = `SH-${day}-${month}-${year}-`;
    setPrefix(shipmentName);
  }

  const handleCheckboxChange = (event, sample) => {
    if (event.target.checked) {
      setSelectedSampleIds((prev) => [
        ...prev,
        {
          sample_id: sample?.["Sample Id"],
          sample_name: sample?.["Sample Name"] || null,
          inspection_status: sample?.inspection_status || null,
        },
      ]);
    } else {
      setSelectedSampleIds((prev) =>
        prev?.filter((item) => item?.sample_id !== sample?.["Sample Id"]),
      );
    }
  };

  const getAllSamples = (p) => {
    setLoading(true);
    getSamples(p)
      .then((res) => {
        setSamplesList(res?.data?.data?.samples || []);
        setPage(res?.data?.metadata?.pagination?.total_pages);

        let already_selected = location?.state?.selected;
        if (already_selected) {
          let selectedSids = [
            {
              sample_id: already_selected?.["Sample Id"],
              sample_name: already_selected?.["Sample Name"] || null,
              inspection_status: already_selected?.inspection_status || null,
            },
          ];

          setSelectedSampleIds(selectedSids);
          setOpenCreateModal(true);
          generateShipmentName();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeSampleToShipment = (id) => {
    const filtered = selectedSampleIds.filter(
      (sample) => sample?.sample_id !== id,
    );
    setSelectedSampleIds(filtered);
  };

  const AddSampleToShipment = (shipmentId) => {
    const sampleIds = selectedSampleIds?.map((item) => item?.sample_id);
    setLoading(true);
    HttpService.put(
      `${drxnaUrl}shipment/${shipmentId}/sample/?ignore_failure=true`,
      { sample_id_list: sampleIds },
    )
      .then((res) => {
        if (res?.data?.data) {
          // navigate(`/shipments`);
          navigate(`/shipment/${shipmentId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [showError, setShowError] = useState(false);
  const creatShipment = () => {
    if (shipmentName?.trim() === "") {
      setShowError(true);
      return;
    }
    setLoading(true);
    HttpService.post(`${drxnaUrl}shipment/`, {
      name: prefix + shipmentName + "",
    })
      .then((res) => {
        if (res?.data?.data?._id) {
          AddSampleToShipment(res?.data?.data?._id);
        }
        setOpenCreateModal(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getShipmentStatus = (status, shipment) => {
    let validId =
      shipment !== "" && shipment !== null && shipment !== undefined;
    let shipment_status;
    if (status === "created" && validId) {
      shipment_status = "In proccess";
    } else if (status === "ready" && validId) {
      shipment_status = "Completed";
    } else {
      shipment_status = "None";
    }

    return shipment_status;
  };

  useEffect(() => {
    getAllSamples(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const commanSeparated =
      selectedSampleIds
        ?.filter((item) => !item?._id)
        ?.map((item) => item?.sample_id)
        .join(",") + ",";

    if (commanSeparated) {
      // setSelectedSamples(commanSeparated);
    }
  }, [selectedSampleIds]);

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Box sx={{ position: "fixed", top: "70px", width: "100%", zIndex: 999 }}>
        {loading && (
          <LinearProgress
            sx={{
              backgroundColor: "#f1f5f9",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#ff6412ad",
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          py: {
            xs: 2,
            md: 4,
          },
          px: {
            xs: 2,
            md: 5,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            mb: 3,
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "16px", sm: "22px" },
              fontWeight: "600",
              color: "#1e293b",
              fontFamily: "Open Sans",
            }}
          >
            Samples.
          </Typography>
          {selectedSampleIds?.length !== 0 && (
            <Button
              sx={{
                textTransform: "none",
                background: "#1e5267",
                ":hover": { background: "#1e5267" },
                fontFamily: "Open Sans",
                borderRadius: "8px",
                height: "45px",
                px: 3,
              }}
              onClick={() => {
                setOpenCreateModal(true);
                generateShipmentName();
              }}
              variant="contained"
            >
              Create Shipment
            </Button>
          )}
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              overflowX: "auto",
              width: "100%",
              mb: 1,
            }}
          >
            {selectedSampleIds?.map((sample, index) => (
              <Box
                variant="outlined"
                color={sample?.inspection_status ? "success" : "warning"}
                sx={{
                  fontFamily: "Open Sans",
                  pl: 2,
                  pr: 1,
                  display: "flex",
                  gap: 2,
                  height: "35px",
                  minWidth: "max-content",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "8px",
                  border: sample?.inspection_status
                    ? "1px solid #16a34a"
                    : "1px solid #eab308",
                  color: sample?.inspection_status ? "#16a34a" : "#eab308",
                }}
                key={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 4,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", sm: "13px" },
                        fontFamily: "Open Sans",
                      }}
                    >
                      Name: {sample?.sample_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "12px", sm: "13px" },
                        fontFamily: "Open Sans",
                      }}
                    >
                      ID: {sample?.sample_id}
                    </Typography>
                  </Box>

                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSampleToShipment(sample?.sample_id);
                    }}
                    sx={{
                      fontSize: "16px",
                      width: { xs: "20px", sm: "25px" },
                      height: { xs: "20px", sm: "25px" },
                      ":hover": { color: "red" },
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              my: { xs: 2, sm: 3 },
              display: { xs: "none", sm: "flex" },
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontFamily: "Open Sans" }}>
              Choose samples below to include in your package.
            </Typography>
          </Box>
          {gridMode ? (
            <Box>
              <Grid container spacing={3}>
                {samplesList &&
                  samplesList.map((sample, index) => (
                    <Grid item xs={12} md={4} xl={3} key={index}>
                      <Card
                        sx={{
                          cursor: "pointer",
                          boxShadow: "0px 1px 14px rgba(0, 0, 0, 0.1)",
                          ":hover": {
                            boxShadow: "0px 1px 14px rgba(0, 0, 0, 0.15)",
                          },
                          minHeight: "200px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/sample/${sample?.["Sample Id"]}`);
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                            }}
                          >
                            {sample?.shipment_id ? (
                              <Button
                                size="sm"
                                variant="outlined"
                                sx={{
                                  textTransform: "none",
                                  fontSize: "12px",
                                  minWidth: "max-content",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/shipment/${sample?.shipment_id}`);
                                }}
                              >
                                View Package
                              </Button>
                            ) : getShipmentStatus(
                                sample?.status,
                                sample?.shipment_id,
                              ) === "None" ? (
                              <Checkbox
                                sx={{
                                  color: "#1e5267",
                                  "&.Mui-checked": {
                                    color: "#1e5267",
                                  },
                                }}
                                value={sample?.["Sample Id"]}
                                checked={selectedSampleIds?.some(
                                  (item) =>
                                    item?.sample_id === sample?.["Sample Id"],
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleCheckboxChange(e, sample);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            ) : (
                              <Box></Box>
                            )}
                          </Box>
                          <Box
                            sx={{
                              mb: 1,
                              display: "flex",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={"600"}
                              sx={{
                                fontFamily: "Open Sans",
                              }}
                            >
                              {sample?.["Sample Name"]}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mb: 1,
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                              }}
                            >
                              Shipment
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  getShipmentStatus(
                                    sample?.status,
                                    sample?.shipment_id,
                                  ) === "In proccess"
                                    ? "#0ea5e9"
                                    : getShipmentStatus(
                                          sample?.status,
                                          sample?.shipment_id,
                                        ) === "Completed"
                                      ? "#22c55e"
                                      : "black",
                                minWidth: "max-content",
                                fontFamily: "Open Sans",
                                ml: 1,
                              }}
                            >
                              {getShipmentStatus(
                                sample?.status,
                                sample?.shipment_id,
                              )}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mb: 1,
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                              }}
                            >
                              Status
                            </Typography>
                            <Typography
                              variant="body2"
                              fontWeight={"600"}
                              sx={{
                                fontFamily: "Open Sans",
                                ml: 1,
                                mt: 0.5,
                              }}
                            >
                              {sample?.["status"]}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mb: 1,
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                              }}
                            >
                              Sample ID
                            </Typography>
                            <Typography
                              variant="body2"
                              fontWeight={"600"}
                              sx={{
                                fontFamily: "Open Sans",
                                ml: 1,
                                mt: 0.5,
                              }}
                            >
                              {sample?.["Sample Id"]}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mb: 1,
                              display: "flex",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Open Sans",
                              }}
                            >
                              Description
                            </Typography>
                            <Typography
                              variant="body2"
                              fontWeight={"600"}
                              sx={{
                                fontFamily: "Open Sans",
                                ml: 1,
                                mt: 0.5,
                              }}
                            >
                              {sample?.["Description"]}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ) : (
            <Box>
              <TableContainer
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  mb: 2,
                }}
              >
                <Table>
                  <TableHead
                    sx={{
                      borderBottom: "1px solid #e2e8f0",
                      position: "sticky",
                      top: 0,
                      left: 0,
                      background: "#fff",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          minWidth: "200px",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Sample Name
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Shipment
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          minWidth: "max-content",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Description
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ borderRight: "1px solid #e2e8f0" }}>
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Status
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Sample ID
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          borderRight: "1px solid #e2e8f0",
                          minWidth: "max-content",
                          display: { xs: "none", lg: "flex" },
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={"600"}
                          sx={{
                            cursor: "pointer",
                            minWidth: "max-content",
                            fontFamily: "Open Sans",
                          }}
                        >
                          Action
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {samplesList.length !== 0 &&
                      samplesList?.map((sample, index) => (
                        <TableRow
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/sample/${sample?.["Sample Id"]}`);
                          }}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? "#FFFFFF" : "#fff3eb",
                            ":hover": {
                              backgroundColor: "#e0f2fe",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 0,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography sx={{ fontFamily: "Open Sans" }}>
                                {sample?.["Sample Name"]}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 2.5,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  color:
                                    getShipmentStatus(
                                      sample?.status,
                                      sample?.shipment_id,
                                    ) === "In proccess"
                                      ? "#0ea5e9"
                                      : getShipmentStatus(
                                            sample?.status,
                                            sample?.shipment_id,
                                          ) === "Completed"
                                        ? "#22c55e"
                                        : "black",
                                  minWidth: "max-content",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                {getShipmentStatus(
                                  sample?.status,
                                  sample?.shipment_id,
                                )}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 0,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  minWidth: "300px",
                                  fontFamily: "Open Sans",
                                }}
                              >
                                {sample?.["Description"]}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              py: 2.5,
                              px: "16px",
                            }}
                          >
                            <Box>
                              <Typography sx={{ fontFamily: "Open Sans" }}>
                                {sample?.["status"]}
                              </Typography>
                            </Box>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              borderBottom: "none",
                              px: "16px",
                            }}
                          >
                            <Typography sx={{ fontFamily: "Open Sans" }}>
                              {sample?.["Sample Id"]}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{
                              borderRight: "1px solid #e2e8f0",
                              minWidth: "max-content",
                              display: "flex",
                            }}
                          >
                            {sample?.shipment_id ? (
                              <Button
                                size="sm"
                                variant="outlined"
                                sx={{
                                  textTransform: "none",
                                  fontSize: "12px",
                                  minWidth: "max-content",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/shipment/${sample?.shipment_id}`);
                                }}
                              >
                                View Package
                              </Button>
                            ) : getShipmentStatus(
                                sample?.status,
                                sample?.shipment_id,
                              ) === "None" ? (
                              <Checkbox
                                sx={{
                                  color: "#1e5267",
                                  "&.Mui-checked": {
                                    color: "#1e5267",
                                  },
                                }}
                                value={sample?.["Sample Id"]}
                                checked={selectedSampleIds?.some(
                                  (item) =>
                                    item?.sample_id === sample?.["Sample Id"],
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleCheckboxChange(e, sample);
                                }}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            ) : (
                              <Box></Box>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            {page > 1 && (
              <Box>
                <Pagination
                  count={page}
                  page={currentPage}
                  onChange={(e, p) => {
                    setCurrentPage(p);
                  }}
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            )}
            {!loading && (
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 1.5,
                  alignItems: "end",
                }}
              >
                <ListFormatIcon
                  onClick={() => {
                    setGridMode(false);
                    localStorage.setItem("sampleView", "list");
                  }}
                  sx={{
                    color: gridMode ? "#666666" : "#ff6412",
                    fontSize: "28px",
                    cursor: "pointer",
                  }}
                />

                <GridFormatIcon
                  onClick={() => {
                    setGridMode(true);
                    localStorage.setItem("sampleView", "grid");
                  }}
                  sx={{
                    color: gridMode ? "#ff6412" : "#666666",
                    fontSize: "28px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Dialog
          fullWidth={true}
          sx={{ height: 450 }}
          maxWidth={"xs"}
          open={openCreateModal}
          onClose={() => {
            setOpenCreateModal(false);
          }}
        >
          <DialogTitle>
            <Typography sx={{ fontFamily: "Open Sans" }}>
              Add Package Name
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{ fontFamily: "Open Sans", minWidth: "max-content" }}
              >
                {prefix}
              </Typography>
              <Input
                value={shipmentName}
                sx={{ width: "100%" }}
                error={showError}
                onChange={(e) => {
                  setShipmentName(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                onClick={creatShipment}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  background: "#1e5267",
                  ":hover": { background: "#1e5267" },
                  borderRadius: "8px",
                  fontFamily: "Open Sans",
                }}
                variant="contained"
              >
                Create
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default LotsPage;
